import { useState } from 'react';
import ReactDOM from 'react-dom/client';

function MyForm() {
    const [num, setName] = useState("");
  
    return (
      <form>
        <label>Enter number:
          <input
            type="number" 
            value={num}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <h1>Square of the number {num} is: {num * num}</h1>   
      </form>
    )
  }

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
root.render(<MyForm />);